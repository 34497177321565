.mini-model-viewer {
  height: 200px;
  background: #000;
  border-radius: 4px;
  overflow: hidden;
  margin: 16px;

  .model-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);

    .model-placeholder {
      text-align: center;
      color: #fff;

      .model-info {
        .model-title {
          font-size: 14px;
          margin-bottom: 8px;
          color: rgba(255, 255, 255, 0.85);
        }

        .model-key {
          font-size: 12px;
          color: rgba(255, 255, 255, 0.45);
        }
      }
    }
  }
} 