.app-store {
  min-height: 100vh;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 64px;
    position: fixed;
    width: 100%;
    z-index: 100;

    .header-left {
      display: flex;
      align-items: center;
      gap: 16px;

      .back-button {
        font-size: 14px;
      }

      .page-title {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  .content {
    padding: 88px 24px 24px;
    background: #f0f2f5;

    .banner-section {
      position: relative;
      margin-bottom: 24px;
      border-radius: 8px;
      overflow: hidden;
      height: 400px;

      // ... 轮播样式保持不变

      .carousel-arrows {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;
        
        .arrow-button {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 44px;
          height: 44px;
          background: rgba(0, 0, 0, 0.2);
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          pointer-events: auto;
          transition: all 0.3s;
          opacity: 0;

          .anticon {
            font-size: 20px;
            color: #fff;
          }

          &:hover {
            background: rgba(0, 0, 0, 0.4);
          }

          &.prev {
            left: 20px;
          }

          &.next {
            right: 20px;
          }
        }
      }

      &:hover {
        .carousel-arrows {
          .arrow-button {
            opacity: 1;
          }
        }
      }

      // 优化轮播图指示器样式
      .ant-carousel {
        .slick-dots {
          bottom: 16px;

          li {
            button {
              width: 24px;
              height: 4px;
              border-radius: 2px;
              background: rgba(255, 255, 255, 0.4);
              transition: all 0.3s;
            }

            &.slick-active {
              button {
                width: 32px;
                background: #fff;
              }
            }
          }
        }
      }
    }

    .search-toolbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      padding: 16px 24px;
      background: #fff;
      border-radius: 8px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);

      .toolbar-left {
        display: flex;
        align-items: center;
        gap: 12px;

        h2 {
          margin: 0;
          font-size: 18px;
          font-weight: bold;
        }

        .app-count {
          color: var(--text-secondary);
          font-size: 14px;
        }
      }

      .toolbar-right {
        .ant-select {
          .ant-select-selector {
            border-radius: 4px;
          }
        }

        .ant-input-search {
          .ant-input {
            border-radius: 4px 0 0 4px;
          }
          
          .ant-input-search-button {
            border-radius: 0 4px 4px 0;
          }
        }
      }
    }

    .app-list {
      .app-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 24px;

        // ... 应用卡片样式保持不变
      }
    }
  }
} 