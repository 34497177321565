:root {
  // 基础颜色
  --color-primary: #1890ff;
  --color-success: #52c41a;
  --color-warning: #faad14;
  --color-error: #ff4d4f;
  
  // 背景色
  --bg-base: #ffffff;
  --bg-light: #f0f2f5;
  --bg-dark: #001529;
  
  // 文字颜色
  --text-primary: rgba(0, 0, 0, 0.85);
  --text-secondary: rgba(0, 0, 0, 0.65);
  --text-disabled: rgba(0, 0, 0, 0.45);
  
  // 边框颜色
  --border-color: #d9d9d9;
  --border-color-split: rgba(0, 0, 0, 0.06);
  
  // 阴影
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  
  // 特殊颜色
  --header-bg: var(--bg-base);
  --sider-bg: var(--bg-dark);
  --card-bg: var(--bg-base);
}

// 深色主题变量
[data-theme='dark'] {
  // 基础颜色保持不变，让主色调在深色模式下也能突出
  --color-primary: #1890ff;
  
  // 背景色
  --bg-base: #141414;
  --bg-light: #1f1f1f;
  --bg-dark: #000000;
  
  // 文字颜色
  --text-primary: rgba(255, 255, 255, 0.85);
  --text-secondary: rgba(255, 255, 255, 0.65);
  --text-disabled: rgba(255, 255, 255, 0.45);
  
  // 边框颜色
  --border-color: #434343;
  --border-color-split: rgba(255, 255, 255, 0.06);
  
  // 阴影
  --box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
  
  // 特殊颜色
  --header-bg: var(--bg-base);
  --sider-bg: var(--bg-dark);
  --card-bg: var(--bg-base);
} 