@import './styles/theme.less';

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--bg-light);
  color: var(--text-primary);
}

#root {
  height: 100%;
  width: 100%;
}

// 通用样式覆盖
.ant-layout {
  background: var(--bg-light) !important;

  &.engineering-list {
    background: var(--bg-light) !important;
  }

  &.project-list-layout {
    background: var(--bg-light) !important;
  }

  .ant-layout-header,
  &.ant-layout-header,
  .header {
    background: var(--header-bg) !important;
    border-bottom: 1px solid var(--border-color-split);
  }
}

.ant-layout-header {
  background: var(--header-bg);
  border-bottom: 1px solid var(--border-color-split);

  .header-left {
    .logo,
    .platform-name {
      color: var(--color-primary) !important;
      background: var(--header-bg);
    }

    .divider {
      background: var(--border-color-split);
    }

    .page-title {
      color: var(--text-primary) !important;
    }
  }
}

.ant-layout-sider {
  background: var(--sider-bg);
}

.ant-card {
  background: var(--card-bg);
  border-color: var(--border-color);
}

.ant-divider {
  border-color: var(--border-color-split);
}

// 按钮和文字样式
.ant-btn {
  &.theme-switch {
    color: var(--text-primary);
    
    &:hover {
      color: var(--color-primary);
      background: var(--bg-light);
    }

    .anticon {
      color: var(--text-primary);
    }
  }
}

.ant-btn-link {
  color: var(--text-primary);
  
  &:hover {
    color: var(--color-primary);
    background: var(--bg-light);
  }
}

// 自定义组件样式
.platform-name {
  color: var(--color-primary) !important;
}

.page-title {
  color: var(--text-primary) !important;
}

.user-info {
  color: var(--text-primary) !important;
  background: var(--header-bg);
  
  .anticon {
    color: var(--text-primary);
  }
  
  .user-role {
    color: var(--text-secondary) !important;
  }

  &:hover {
    background: var(--bg-light) !important;
  }
}

// 下拉菜单样式
.ant-dropdown {
  .ant-dropdown-menu {
    background: var(--bg-base);
    border: 1px solid var(--border-color);
    
    .ant-dropdown-menu-item {
      color: var(--text-primary);
      
      &:hover {
        background: var(--bg-light);
      }
      
      .anticon {
        color: var(--text-primary);
      }
    }

    .ant-dropdown-menu-item-divider {
      background-color: var(--border-color-split);
    }
  }
}

.engineering-card {
  .engineering-cover {
    background: var(--bg-light) !important;
  }
  
  .engineering-icon {
    color: var(--color-primary) !important;
  }
  
  &.add-card {
    background: var(--bg-light) !important;
    border-color: var(--border-color) !important;
    
    &:hover {
      border-color: var(--color-primary) !important;
      
      .add-icon,
      .add-text {
        color: var(--color-primary) !important;
      }
    }
    
    .add-icon,
    .add-text {
      color: var(--text-disabled) !important;
    }
  }
}

.readonly-info {
  border-color: var(--border-color-split) !important;
  
  .label {
    color: var(--text-primary) !important;
  }
  
  .value {
    color: var(--text-secondary) !important;
  }
}

.form-tips {
  border-color: var(--border-color-split) !important;
  
  p {
    color: var(--text-disabled) !important;
  }
}

// 登录页面卡片样式
.login-card {
  .ant-card-head {
    background: var(--header-bg);
    border-bottom-color: var(--border-color-split);

    .ant-card-head-title {
      color: var(--color-primary) !important;
    }
  }

  .ant-card-body {
    background: var(--card-bg);
  }
}

// 深色模式特殊处理
[data-theme='dark'] {
  .ant-btn {
    &.theme-switch,
    &.user-info {
      background: var(--header-bg);
      
      &:hover {
        background: var(--bg-light);
      }
    }
  }

  .ant-dropdown {
    .ant-dropdown-menu {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.45);
      
      .ant-dropdown-menu-item {
        &:hover {
          background: var(--bg-light);
        }
      }
    }
  }

  .ant-layout-header {
    .header-left {
      .logo,
      .platform-name {
        background: var(--header-bg);
      }
    }
  }

  .login-card {
    .ant-card-head {
      background: var(--header-bg);
    }
  }

  .ant-layout {
    background: var(--bg-base) !important;

    &.engineering-list,
    &.project-list-layout {
      background: var(--bg-base) !important;

      .project-list {
        background: var(--bg-base) !important;
      }

      .ant-table-wrapper {
        background: var(--bg-base) !important;

        .ant-table {
          background: var(--bg-base) !important;

          .ant-table-container {
            background: var(--bg-base) !important;
          }

          .ant-table-content {
            background: var(--bg-base) !important;
          }

          .ant-table-thead > tr > th {
            background: var(--bg-light) !important;
            color: var(--text-primary) !important;
            border-bottom: 1px solid var(--border-color-split) !important;

            &::before {
              background-color: var(--border-color-split) !important;
            }
          }

          .ant-table-tbody {
            background: var(--bg-base) !important;

            > tr {
              background: var(--bg-base) !important;

              > td {
                background: var(--bg-base) !important;
                color: var(--text-primary) !important;
                border-bottom: 1px solid var(--border-color-split) !important;
              }

              &:hover > td {
                background: var(--bg-light) !important;
              }
            }
          }
        }

        .ant-table-footer,
        .ant-table-summary {
          background: var(--bg-base) !important;
        }
      }

      .ant-card,
      .ant-card-bordered {
        background: var(--bg-base) !important;
        border-color: var(--border-color) !important;

        .ant-card-body {
          background: var(--bg-base) !important;
        }

        .ant-card-head {
          background: var(--bg-base) !important;
          border-bottom-color: var(--border-color-split) !important;
        }
      }

      .ant-pagination {
        .ant-pagination-item {
          background: var(--bg-base);
          border-color: var(--border-color);
          
          a {
            color: var(--text-primary);
          }

          &:hover {
            border-color: var(--color-primary);
            
            a {
              color: var(--color-primary);
            }
          }

          &.ant-pagination-item-active {
            background: var(--color-primary);
            border-color: var(--color-primary);
            
            a {
              color: #fff;
            }
          }
        }

        .ant-pagination-prev,
        .ant-pagination-next {
          button {
            background: var(--bg-base);
            border-color: var(--border-color);
            color: var(--text-primary);

            &:hover {
              border-color: var(--color-primary);
              color: var(--color-primary);
            }
          }
        }
      }
    }

    .ant-layout-content {
      background: var(--bg-base) !important;
    }
  }

  .engineering-content {
    background: var(--bg-base) !important;

    .engineering-card {
      .ant-card-body {
        background: var(--bg-base) !important;
      }

      &.add-card {
        background: var(--bg-light) !important;
        border-color: var(--border-color) !important;

        &:hover {
          border-color: var(--color-primary) !important;
          background: var(--bg-base) !important;
        }
      }
    }
  }

  // 全局卡片和模态框样式
  .ant-card,
  .ant-card-bordered {
    background: var(--bg-base) !important;
    border-color: var(--border-color) !important;

    .ant-card-body {
      background: var(--bg-base) !important;
    }

    .ant-card-head {
      background: var(--bg-base) !important;
      border-bottom-color: var(--border-color-split) !important;
    }
  }

  .ant-modal {
    .ant-modal-content {
      background: var(--bg-base) !important;

      .ant-modal-header {
        background: var(--bg-base) !important;
        border-bottom-color: var(--border-color-split) !important;
      }

      .ant-modal-body {
        background: var(--bg-base) !important;
      }

      .ant-modal-footer {
        background: var(--bg-base) !important;
        border-top-color: var(--border-color-split) !important;
      }
    }
  }
} 