.model-viewer {
  width: 100%;
  height: 100%;
  background: #000;
  overflow: hidden;

  .model-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
    position: relative;

    .model-content {
      width: 100%;
      height: 100%;

      .model-placeholder {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(135deg, #1a1a1a 0%, #0a0a0a 100%);
        color: #fff;

        .model-info {
          text-align: center;

          .model-title {
            font-size: 24px;
            margin-bottom: 16px;
            color: rgba(255, 255, 255, 0.85);
          }

          .model-key {
            font-size: 16px;
            color: rgba(255, 255, 255, 0.45);
          }
        }
      }
    }

    .empty-placeholder {
      color: rgba(255, 255, 255, 0.45);
      font-size: 16px;
    }
  }
} 