.component-detail {
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    height: 80px;

    .header-left {
      display: flex;
      align-items: center;

      .back-button {
        margin-right: 16px;
        font-size: 14px;
      }

      .logo {
        font-size: 24px;
        font-weight: bold;
        color: #1890ff;
        line-height: 80px;
        padding: 0 16px;
      }

      .divider {
        width: 1px;
        height: 32px;
        background: rgba(0, 0, 0, 0.1);
        margin: 0 16px;
      }

      .page-title {
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .header-right {
      .user-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 4px 8px;
        height: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }

        .user-role {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      .library-count {
        margin-left: 8px;
        color: var(--text-secondary);
        font-size: 12px;
      }
    }
  }

  .component-sider {
    height: calc(100vh - 80px);
    background: #fff;
    border-right: 1px solid #f0f0f0;
    position: relative;
    display: flex;

    .sider-content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      border-right: 1px solid #f0f0f0;

      .category-section {
        flex: none;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #f0f0f0;
        position: relative;
      }

      .component-section {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
      }

      .section-header {
        padding: 16px;
        font-size: 16px;
        font-weight: bold;
        border-bottom: 1px solid #f0f0f0;
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-actions {
          display: flex;
          gap: 8px;
          align-items: center;

          .ant-btn {
            padding: 0;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.ant-btn-link {
              color: rgba(0, 0, 0, 0.45);

              &:hover {
                color: #1890ff;
              }
            }
          }
        }
      }

      .ant-tree {
        flex: 1;
        padding: 16px;
        overflow: auto;
      }

      .component-list {
        flex: 1;
        overflow-y: auto;
        padding: 16px;

        .component-cards {
          margin: 0;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 16px;
          width: 100%;

          .component-card {
            text-align: center;
            padding: 0;
            transition: all 0.3s;
            aspect-ratio: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            &.selected {
              border-color: #1890ff;
              background: #e6f7ff;
            }

            .preview-area {
              flex: 1;
              background: #f5f5f5;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 16px;
              border-bottom: 1px solid #f0f0f0;

              .component-icon {
                font-size: 32px;
                color: #1890ff;
              }
            }

            .component-title {
              padding: 12px;
              font-size: 14px;
              color: rgba(0, 0, 0, 0.85);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
              background: #fff;
            }

            &:hover {
              border-color: #1890ff;
              transform: translateY(-2px);
              box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
            }

            &.example-card {
              border: 1px dashed #1890ff;
              background: #f0f7ff;

              .example-tag {
                position: absolute;
                top: 8px;
                right: 8px;
                padding: 2px 8px;
                background: #1890ff;
                color: #fff;
                border-radius: 2px;
                font-size: 12px;
              }
            }
          }
        }
      }

      .resize-handle-horizontal {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
        height: 10px;
        cursor: row-resize;
        background: transparent;
        z-index: 1;

        &:hover {
          background: rgba(0, 0, 0, 0.1);
        }

        &:active {
          background: rgba(0, 0, 0, 0.2);
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 2px;
          background: rgba(0, 0, 0, 0.15);
          border-radius: 1px;
        }
      }
    }

    .resize-handle {
      position: absolute;
      right: -5px;
      top: 0;
      bottom: 0;
      width: 10px;
      cursor: col-resize;
      background: transparent;
      z-index: 1;

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }

      &:active {
        background: rgba(0, 0, 0, 0.2);
      }
    }

    .geometry-panel {
      width: 300px;
      height: 100%;
      display: flex;
      flex-direction: column;
      background: #fff;
      border-left: 1px solid #f0f0f0;
      margin-left: -1px;

      .panel-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .section-header {
          padding: 16px;
          font-size: 16px;
          font-weight: bold;
          border-bottom: 1px solid #f0f0f0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .ant-btn {
            padding: 0;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba(0, 0, 0, 0.45);

            &:hover {
              color: #1890ff;
            }
          }
        }

        .ant-tree {
          flex: 1;
          padding: 16px;
          overflow: auto;
        }
      }

      .preview-section {
        height: 200px;
        border-top: 1px solid #f0f0f0;
        flex-shrink: 0;
      }
    }
  }

  > .ant-layout {
    flex: 1;
    overflow: hidden;

    .preview-area {
      flex: 1;
      padding: 0;
      background: #f5f5f5;
      display: flex;
      overflow: hidden;
    }
  }

  .react-resizable {
    position: relative;
    background: #fff;
  }

  .react-resizable-handle {
    position: absolute;
    right: -5px;
    top: 0;
    bottom: 0;
    width: 10px;
    cursor: col-resize;
    z-index: 1;
  }

  .resize-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none;

    &.active {
      display: block;
    }
  }
}

// 添加下拉菜单样式
.library-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
  min-width: 200px;

  .library-info {
    display: flex;
    flex-direction: column;

    .library-name {
      font-size: 14px;
      color: var(--text-primary);
    }

    .library-type {
      font-size: 12px;
      color: var(--text-secondary);
    }
  }

  .library-count {
    font-size: 12px;
    color: var(--text-secondary);
  }
}

// 禁用状态样式
.ant-dropdown-menu-item-disabled {
  .library-menu-item {
    .library-info {
      .library-name {
        color: var(--text-secondary);
      }

      .library-type {
        color: var(--text-quaternary);
      }
    }

    .library-count {
      color: var(--text-quaternary);
    }
  }
} 