.template-detail {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    // ... 复用之前的头部样式
  }

  .main-content {
    height: calc(100vh - 80px);
    display: flex;
    position: relative;

    .preview-area {
      flex: 1.1;
      min-width: 0;
      padding: 24px;
      background: #f5f5f5;

      .cad-container {
        height: 100%;
        background: #fff;
        border: 1px solid #f0f0f0;
        border-radius: 2px;
        position: relative;
        overflow: hidden;

        .placeholder-text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #999;
          font-size: 24px;
        }

        .added-components {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          .added-component {
            position: absolute;
            display: flex;
            align-items: center;

            img {
              max-width: 200px;
              max-height: 200px;
            }

            .ant-btn {
              margin-left: 8px;
            }
          }
        }
      }
    }

    .resize-handle {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 5px;
      background: transparent;
      cursor: col-resize;
      transition: background-color 0.3s;
      z-index: 1;

      &:hover,
      &:active {
        background: var(--color-primary);
        opacity: 0.2;
      }
    }

    .components-sider {
      flex: 1;
      min-width: 300px;
      max-width: 800px;
      transition: none;
      background: #fff;
      border-left: 1px solid #f0f0f0;
      position: relative;

      .sider-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        border-bottom: 1px solid var(--border-color-split);

        h3 {
          margin: 0;
          font-size: 16px;
          font-weight: bold;
        }
      }

      .ant-tabs-content {
        height: calc(100vh - 160px);
        overflow-y: auto;
      }

      .components-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 20px;
        padding: 20px;

        .component-card {
          transition: all 0.3s;

          &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          }

          .ant-card-cover {
            height: 140px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--bg-light);
            border-bottom: 1px solid var(--border-color-split);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .ant-card-meta {
            margin-bottom: 16px;

            .ant-card-meta-title {
              font-size: 15px;
              margin-bottom: 8px;
            }

            .component-info {
              .component-type {
                color: var(--color-primary);
                font-size: 12px;
                margin-bottom: 4px;
              }

              .component-desc {
                color: var(--text-secondary);
                font-size: 12px;
                line-height: 1.5;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
          }

          .ant-btn {
            width: 100%;
          }
        }
      }

      .ant-tabs {
        height: 100%;

        .ant-tabs-nav {
          margin-bottom: 0;
          padding: 0 16px;
          background: #fafafa;
          border-bottom: 1px solid var(--border-color-split);
        }

        .ant-tabs-content-holder {
          overflow-y: auto;
        }
      }
    }
  }
} 