.cad-viewer {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .ribbon-header {
    height: 80px;
    padding: 0;
    line-height: normal;
    background: #f0f2f5;
    border-bottom: 1px solid #d9d9d9;

    .ribbon-menu {
      background: transparent;
      border-bottom: none;
    }
  }

  .project-sider {
    background: #fff;
    border-right: 1px solid #d9d9d9;

    .sider-header {
      padding: 16px;
      font-weight: bold;
      border-bottom: 1px solid #d9d9d9;
    }

    .ant-tree {
      padding: 16px;
    }
  }

  .drawing-area {
    background: #f5f5f5;
    padding: 16px;
    position: relative;

    .canvas-container {
      background: #fff;
      height: 100%;
      border: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;

      .placeholder-text {
        color: #999;
        font-size: 24px;
      }
    }
  }

  .property-sider {
    background: #fff;
    border-left: 1px solid #d9d9d9;

    .ant-tabs {
      height: 100%;

      .ant-tabs-content {
        height: 100%;
        padding: 16px;
      }
    }

    .property-panel,
    .layer-panel {
      height: 100%;
    }
  }

  .status-bar {
    height: 32px;
    padding: 0 16px;
    background: #f0f2f5;
    border-top: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    gap: 24px;

    .status-item {
      color: rgba(0, 0, 0, 0.65);
      font-size: 12px;
    }
  }
} 