:root {
  --bg-light: #f5f7fa;
  --card-bg: #ffffff;
}

.project-list-layout {
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 1000;
    height: 80px;

    .header-left {
      display: flex;
      align-items: center;

      .logo {
        font-size: 24px;
        font-weight: bold;
        color: #1890ff;
        line-height: 80px;
        padding: 0 16px;
      }

      .divider {
        width: 1px;
        height: 32px;
        background: rgba(0, 0, 0, 0.1);
        margin: 0 16px;
      }

      .page-title {
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .header-right {
      .user-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 4px 8px;
        height: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }

        .user-role {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .project-sider {
    background: #eef0f2;
    position: fixed;
    height: calc(100vh - 80px);
    left: 0;
    top: 80px;
    width: 200px;
    border-right: 1px solid var(--border-color-split);

    .sider-trigger {
      height: 56px;
      line-height: 56px;
      text-align: right;
      padding: 0 16px;
      cursor: pointer;
      transition: all 0.3s;
      color: rgba(0, 0, 0, 0.85);
      
      &:hover {
        color: var(--color-primary);
        background: rgba(24, 144, 255, 0.1);
      }
    }

    .ant-menu {
      border-right: none;
      background: transparent;

      .ant-menu-item {
        margin: 4px 0;
        height: 56px;
        line-height: 56px;
        font-size: 16px;
        padding-left: 24px !important;
        color: rgba(0, 0, 0, 0.85) !important;
        
        .anticon {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.85);
        }

        &:hover {
          color: var(--color-primary) !important;
          background: rgba(24, 144, 255, 0.1);
          
          .anticon {
            color: var(--color-primary);
          }
        }

        &.ant-menu-item-selected {
          background-color: var(--color-primary);
          color: #fff !important;
          font-weight: 500;

          .anticon {
            color: #fff;
          }
        }
      }

      .ant-menu-item-divider {
        margin: 8px 0;
        border-color: rgba(0, 0, 0, 0.06);
      }
    }
  }

  .project-list {
    margin-left: 200px;
    padding: 88px 24px 24px;
    background: var(--bg-light);
    height: calc(100vh - 80px);
    width: calc(100% - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s;

    &.collapsed {
      margin-left: 80px;
      width: calc(100% - 80px);
    }

    .search-bar {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      z-index: 10;
      background: var(--card-bg);
      padding: 16px 0;
      width: 100%;
    }

    .ant-card {
      background: var(--card-bg);
      .ant-card-body {
        padding: 16px;
      }
    }

    .ant-table-wrapper {
      background: #fff;
      width: 100%;
      
      .ant-table {
        width: 100%;
      }
      
      .column-center {
        .ant-table-column-title {
          text-align: center;
        }
      }
      
      .ant-table-cell {
        .ant-btn-link {
          padding: 4px 8px;
          height: auto;
        }
      }
    }

    .component-library {
      padding: 16px;

      .component-group {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        .group-header {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          padding-bottom: 8px;
          border-bottom: 1px solid var(--border-color-split);

          h3 {
            margin: 0;
            font-size: 16px;
            font-weight: bold;
            color: var(--text-primary);
          }

          .group-count {
            margin-left: 12px;
            color: var(--text-secondary);
            font-size: 14px;
          }
        }

        .component-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          .ant-card {
            width: 100%;
            
            .ant-card-cover {
              height: 140px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--bg-light);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .ant-card-meta-title {
              font-size: 14px;
              margin-bottom: 4px;
            }

            .ant-card-meta-description {
              font-size: 12px;
              color: var(--text-secondary);
            }
          }
        }
      }
    }

    .all-content {
      .content-group {
        margin-bottom: 32px;

        &:last-child {
          margin-bottom: 0;
        }

        .group-header {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          padding-bottom: 8px;
          border-bottom: 1px solid var(--border-color-split);
          cursor: pointer;
          user-select: none;

          &:hover {
            background-color: var(--bg-light);
          }

          .header-left {
            display: flex;
            align-items: center;
            gap: 8px;

            .anticon {
              font-size: 12px;
              color: var(--text-secondary);
            }

            h3 {
              margin: 0;
              font-size: 16px;
              font-weight: bold;
              color: var(--text-primary);
            }

            .group-count {
              margin-left: 12px;
              color: var(--text-secondary);
              font-size: 14px;
            }
          }
        }

        .preview-grid {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 16px;

          .preview-card {
            background: var(--card-bg);
            
            .ant-card-cover {
              height: 120px;
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--bg-light);

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .ant-card-meta-title {
              font-size: 14px;
              margin-bottom: 4px;
            }

            .ant-card-meta-description {
              font-size: 12px;
              color: var(--text-secondary);
            }
          }
        }
      }
    }
  }

  .readonly-info {
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px dashed #d9d9d9;

    .info-item {
      margin-bottom: 8px;
      color: rgba(0, 0, 0, 0.65);
      
      .label {
        display: inline-block;
        width: 100px;
        color: rgba(0, 0, 0, 0.85);
      }
      
      .value {
        margin-left: 8px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .form-tips {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px dashed #d9d9d9;
    
    p {
      color: rgba(0, 0, 0, 0.45);
      margin: 0;
      font-size: 13px;
    }
  }

  .template-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    gap: 20px;
    padding: 16px;

    .template-card {
      background: var(--card-bg);
      
      .ant-card-cover {
        height: 160px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-light);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .ant-card-meta-title {
        font-size: 16px;
        margin-bottom: 8px;
      }

      .template-description {
        margin-top: 4px;
        color: var(--text-secondary);
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }

  .preview-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    .preview-card {
      background: var(--card-bg);
      
      .ant-card-cover {
        height: 140px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-light);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .ant-card-meta-title {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .ant-card-meta-description {
        font-size: 12px;
        color: var(--text-secondary);
      }

      .template-description {
        margin-top: 4px;
        color: var(--text-secondary);
        font-size: 12px;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .app-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 24px;
    padding: 16px;

    .app-card {
      background: var(--card-bg);
      .app-header {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 12px;

        .app-icon {
          width: 56px;
          height: 56px;
          border-radius: 8px;
        }

        .app-info {
          .app-name {
            font-size: 16px;
            font-weight: bold;
            color: var(--text-primary);
            margin-bottom: 4px;
          }

          .app-category {
            font-size: 12px;
            color: var(--text-secondary);
          }
        }
      }

      .app-description {
        font-size: 14px;
        color: var(--text-secondary);
        margin-bottom: 12px;
        line-height: 1.5;
      }

      .app-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: var(--text-secondary);

        .app-version {
          background: var(--bg-light);
          padding: 2px 8px;
          border-radius: 4px;
        }
      }
    }
  }

  .preview-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    .preview-card {
      background: var(--card-bg);
      
      .ant-card-cover {
        height: 120px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-light);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .ant-card-meta-title {
        font-size: 14px;
        margin-bottom: 4px;
      }

      .ant-card-meta-description {
        font-size: 12px;
        color: var(--text-secondary);
      }

      .template-description {
        margin-top: 4px;
        color: var(--text-secondary);
        font-size: 12px;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    .app-card {
      background: var(--card-bg);
      .app-header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 8px;

        .app-icon {
          width: 36px;
          height: 36px;
          border-radius: 6px;
        }

        .app-info {
          .app-name {
            font-size: 14px;
            font-weight: bold;
            color: var(--text-primary);
            margin-bottom: 2px;
          }

          .app-category {
            font-size: 12px;
            color: var(--text-secondary);
          }
        }
      }

      .app-description {
        font-size: 12px;
        color: var(--text-secondary);
        margin-bottom: 8px;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .app-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: var(--text-secondary);

        .app-version {
          background: var(--bg-light);
          padding: 1px 6px;
          border-radius: 4px;
        }
      }
    }
  }

  .template-container {
    padding: 16px;

    .template-section {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--border-color-split);

        .header-left {
          display: flex;
          align-items: center;
          gap: 12px;

          h3 {
            margin: 0;
            font-size: 18px;
            font-weight: bold;
            color: var(--text-primary);
          }

          .section-count {
            color: var(--text-secondary);
            font-size: 14px;
          }
        }
      }

      .template-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 24px;

        .template-card {
          background: var(--card-bg);
          
          .ant-card-cover {
            height: 180px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--bg-light);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .ant-card-meta-title {
            font-size: 16px;
            margin-bottom: 8px;
          }

          .template-description {
            margin-top: 4px;
            color: var(--text-secondary);
            font-size: 12px;
            line-height: 1.5;
          }
        }
      }
    }
  }

  .material-container {
    padding: 16px;

    .material-section {
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
      }

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid var(--border-color-split);

        .header-left {
          display: flex;
          align-items: center;
          gap: 12px;

          h3 {
            margin: 0;
            font-size: 18px;
            font-weight: bold;
            color: var(--text-primary);
          }

          .section-count {
            color: var(--text-secondary);
            font-size: 14px;
          }
        }
      }

      .material-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        .material-card {
          background: var(--card-bg);
          
          .ant-card-cover {
            height: 150px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--bg-light);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .material-info {
            .material-category {
              color: var(--text-secondary);
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .app-detail-modal {
    .app-detail-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 24px;

      .app-basic-info {
        display: flex;
        gap: 16px;

        .app-icon {
          width: 64px;
          height: 64px;
          border-radius: 12px;
        }

        .app-info {
          .app-name {
            margin: 0 0 8px;
            font-size: 24px;
            font-weight: bold;
          }

          .app-meta {
            display: flex;
            gap: 16px;
            color: var(--text-secondary);

            span {
              font-size: 14px;
            }
          }
        }
      }

      .app-price {
        .ant-btn {
          min-width: 100px;
        }
      }
    }

    .app-screenshots {
      margin-bottom: 24px;
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid var(--border-color-split);

      .screenshot-item {
        height: 450px;
        background: var(--bg-light);

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .ant-carousel .slick-dots {
        bottom: 12px;

        li button {
          background: #fff;
          opacity: 0.7;
        }

        li.slick-active button {
          opacity: 1;
        }
      }
    }

    .app-description,
    .app-features {
      margin-bottom: 24px;

      h3 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
      }

      p {
        color: var(--text-secondary);
        line-height: 1.6;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px;

        li {
          position: relative;
          padding-left: 24px;
          color: var(--text-secondary);

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--color-primary);
          }
        }
      }
    }

    .app-actions {
      text-align: center;
      padding-top: 24px;
      border-top: 1px solid var(--border-color-split);
    }
  }

  .material-detail-modal {
    .material-detail-content {
      .material-preview {
        margin-bottom: 24px;
        border: 1px solid var(--border-color-split);
        border-radius: 4px;
        overflow: hidden;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--bg-light);

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .setting-section {
        margin-bottom: 24px;

        h3 {
          font-size: 16px;
          font-weight: bold;
          margin-bottom: 16px;
          padding-bottom: 8px;
          border-bottom: 1px solid var(--border-color-split);
        }

        .ant-form-item {
          margin-bottom: 16px;
        }

        .ant-input[type="color"] {
          padding: 0;
          height: 32px;
        }
      }
    }
  }

  .appstore-container {
    padding: 24px;

    .banner-section {
      margin-bottom: 32px;
      border-radius: 8px;
      overflow: hidden;
      height: 400px;

      .banner-item {
        position: relative;
        height: 400px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .banner-content {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          padding: 32px;
          background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
          color: #fff;

          h2 {
            color: #fff;
            font-size: 28px;
            margin-bottom: 8px;
          }

          p {
            font-size: 16px;
            margin: 0;
            opacity: 0.9;
          }
        }
      }

      .ant-carousel {
        height: 100%;

        .slick-slide {
          height: 400px;
        }

        .slick-dots {
          bottom: 16px;

          li button {
            background: #fff;
            opacity: 0.7;
          }

          li.slick-active button {
            opacity: 1;
          }
        }
      }
    }

    .section-container {
      margin-bottom: 48px;

      .section-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        h2 {
          margin: 0;
          font-size: 20px;
          font-weight: bold;
        }

        .ant-btn-link {
          font-size: 14px;
        }
      }

      .app-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        gap: 24px;
      }
    }
  }

  .content-header {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .project-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
    padding: 16px;
  }

  .project-card {
    .ant-card-meta {
      .ant-card-meta-detail {
        .ant-card-meta-title {
          margin-bottom: 16px;
          color: #1890ff;
        }
      }
    }

    .project-card-content {
      .project-info {
        p {
          margin-bottom: 8px;
          color: rgba(0, 0, 0, 0.65);
          
          strong {
            color: rgba(0, 0, 0, 0.85);
            margin-right: 8px;
          }
        }
      }

      .last-open-time {
        margin-top: 8px;
        padding-top: 8px;
        border-top: 1px dashed #e8e8e8;
        
        p {
          color: rgba(0, 0, 0, 0.45);
          margin-bottom: 0;
          
          strong {
            color: rgba(0, 0, 0, 0.65);
          }
        }
      }
    }

    .ant-card-actions {
      background: #fafafa;
    }
  }

  .view-toggle {
    margin-left: auto;
  }

  .storage-plans {
    .plans-description {
      text-align: center;
      margin-bottom: 24px;

      h3 {
        font-size: 24px;
        margin-bottom: 8px;
      }

      p {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .plans-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      padding: 16px 0;

      .plan-card {
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        border: 2px solid transparent;

        &.selected {
          border-color: #1890ff;
        }

        &.recommended {
          transform: scale(1.05);
          box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);

          .recommended-tag {
            position: absolute;
            top: 0;
            right: 24px;
            background: #1890ff;
            color: #fff;
            padding: 2px 12px;
            border-radius: 0 0 8px 8px;
          }
        }

        .plan-header {
          text-align: center;
          padding: 24px;
          border-bottom: 1px solid #f0f0f0;

          h3 {
            font-size: 20px;
            margin-bottom: 16px;
          }

          .plan-price {
            margin-bottom: 8px;

            .price {
              font-size: 32px;
              font-weight: bold;
              color: #1890ff;
            }

            .period {
              color: rgba(0, 0, 0, 0.45);
              margin-left: 4px;
            }
          }

          .plan-capacity {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.65);
          }
        }

        .plan-features {
          padding: 24px;

          .feature-item {
            margin-bottom: 12px;
            color: rgba(0, 0, 0, 0.65);

            .anticon {
              color: #52c41a;
              margin-right: 8px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
} 