.engineering-list {
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    background: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    z-index: 1000;
    height: 80px;

    .header-left {
      display: flex;
      align-items: center;

      .platform-name {
        font-size: 24px;
        font-weight: bold;
        color: #1890ff;
        line-height: 80px;
        padding: 0 16px;
      }

      .divider {
        width: 1px;
        height: 32px;
        background: rgba(0, 0, 0, 0.1);
        margin: 0 16px;
      }

      .page-title {
        font-size: 20px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .header-right {
      .user-info {
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        padding: 4px 8px;
        height: auto;
        display: flex;
        align-items: center;
        gap: 8px;

        &:hover {
          background: rgba(0, 0, 0, 0.04);
        }

        .user-role {
          margin-left: 8px;
          color: rgba(0, 0, 0, 0.45);
        }
      }
    }
  }

  .main-content {
    margin-top: 80px;
    height: calc(100vh - 80px);
    display: flex;
    overflow: hidden;
  }

  .specialty-sider {
    background: #001529;
    position: fixed;
    height: calc(100vh - 80px);
    left: 0;
    top: 80px;

    .sider-header {
      height: 50px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      .back-button {
        color: #fff;
        width: 100%;
        text-align: left;
        padding: 0;
        height: auto;

        &:hover {
          color: #1890ff;
        }
      }
    }

    .ant-menu {
      border-right: none;
      background: transparent;

      .ant-menu-item {
        margin: 0;
        height: 50px;
        line-height: 50px;

        &:hover {
          color: #1890ff;
        }

        &.ant-menu-item-selected {
          background-color: #1890ff;
        }
      }
    }
  }

  .engineering-content {
    margin-left: 180px;
    padding: 16px;
    background: #f0f2f5;
    height: 100%;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .row-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      gap: 16px;
      padding: 0 16px;
    }

    .engineering-card {
      position: relative;
      transition: all 0.3s;
      width: 100%;
      margin: 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        pointer-events: none;
        z-index: 10;
      }

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

        &::after {
          border-color: #1890ff;
        }
      }

      .engineering-cover {
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f5f5f5;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          height: 1px;
          background: #d9d9d9;
          z-index: 10;
        }

        .engineering-icon {
          font-size: 48px;
          color: #1890ff;
        }
      }

      .ant-card-meta-title {
        text-align: center;
        margin: 4px 0;
        font-size: 16px;
      }

      .ant-card-body {
        padding: 16px 16px 8px;
      }

      .rename-input {
        margin: -4px -11px;
        
        .ant-input {
          text-align: center;
          font-size: 16px;
          padding: 4px 8px;
          border-radius: 4px;
          
          &:focus {
            box-shadow: none;
          }
        }
      }

      .ant-card-actions {
        background: transparent;
        border-top: none;
        
        li {
          margin: 0;
          
          .ant-btn-text {
            color: rgba(0, 0, 0, 0.45);
            
            &:hover {
              color: #1890ff;
            }
          }
        }
      }

      &.add-card {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: #fafafa;

        &::after {
          border: 2px dashed #d9d9d9;
        }

        &:hover {
          &::after {
            border-color: #1890ff;
          }
          background: #f0f5ff;
          
          .add-icon,
          .add-text {
            color: #1890ff;
          }
        }

        .add-card-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100%;
          padding: 24px;

          .add-icon {
            font-size: 32px;
            color: #999;
            margin-bottom: 8px;
          }

          .add-text {
            color: #999;
            font-size: 14px;
          }
        }
      }
    }
  }

  .config-description {
    margin-bottom: 16px;
    padding: 12px 16px;
    background: #f5f5f5;
    border-radius: 4px;
    
    p {
      margin: 0;
      color: var(--text-secondary);
      font-size: 14px;
    }
  }

  .config-table {
    .ant-select {
      .ant-select-selector {
        border-radius: 4px;
      }
    }

    .ant-table-thead {
      > tr > th {
        background: #fafafa;
        font-weight: 500;
      }
    }

    .ant-table-tbody {
      > tr > td {
        padding: 12px 16px;
      }
    }

    .component-type-option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 0;

      .type-name {
        font-size: 14px;
        color: var(--text-primary);
      }

      .type-category {
        font-size: 12px;
        color: var(--text-secondary);
        background: var(--bg-light);
        padding: 2px 8px;
        border-radius: 10px;
      }
    }

    .ant-select-item-option-content {
      .component-type-option {
        .type-category {
          opacity: 0.8;
        }
      }
    }

    .ant-select-selection-item {
      .component-type-option {
        .type-category {
          background: var(--color-primary-bg);
          color: var(--color-primary);
        }
      }
    }
  }
} 